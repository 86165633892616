import * as React from 'react';

const AdditionalAssistance = () => (
  <div className="bg-neutral-100 rounded-xl flex items-center justify-center flex-col my-12 text-center p-10">
    <div className="sub-heading-medium">Do you need additional assistance?</div>
    <p className="mb-0 mt-6 px-2">Our friendly support team is here to help. Contact Printivity</p>
  </div>
);

export default AdditionalAssistance;

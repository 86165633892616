import * as React from 'react';
import Nav from '../Nav';
import Form from './ContactSalesForm';
import Header from './Header';
import AdditionalAssistance from './AdditionalAssistance';
import Copyright from '../Copyright';

const ContactSales = () => (
  <>
    <Nav logoCentered />
    <div className="flex justify-center text-center">
      <div className="max-w-md">
        <Header />
        <Form />
        <AdditionalAssistance />
      </div>
    </div>
    <Copyright />
  </>
);

export default ContactSales;

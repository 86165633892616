export interface UserInput {
  name: string;
  email: string;
  phoneNumber: string;
}

export const emptyUserInput = {
  name: '',
  email: '',
  phoneNumber: '',
};

export interface ContactSalesRequest {
  companyName: string;
  industry: string;
  companySize: string;
  annualPrintSpend: string;
  notes: string;
  isCurrentCustomer: boolean;
}

export const emptyContactSalesRequest: ContactSalesRequest = {
  companyName: '',
  industry: '',
  companySize: '1 - 9',
  annualPrintSpend: '$0 - $4,999',
  notes: '',
  isCurrentCustomer: false,
};

export interface ContactSalesFormType {
  userInput: UserInput;
  contactPrintivityCloudSalesRequest: ContactSalesRequest;
}

export const emptyContactSalesRequestForm: ContactSalesFormType = {
  userInput: emptyUserInput,
  contactPrintivityCloudSalesRequest: emptyContactSalesRequest,
};

import * as React from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { object, string, boolean } from 'yup';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import { emptyContactSalesRequestForm } from './types';
import { phoneRegex } from 'utils/schema';
import { submitForm } from 'api/contactUs';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import Input from 'styleguide/components/Formik/Input/Input';
import { Textarea } from 'styleguide/components/Formik';
import Button from 'styleguide/components/Button/Button';
import { Status } from 'libs/utils/api/types';
import { Label } from 'styleguide/components/forms';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import { companySizes, annualPrintSpendOptions } from './formOptions';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';

const contactSalesRequestSchema = object().shape({
  userInput: object().shape({
    name: string().required('Name is required'),
    email: string().email('Please enter a valid email.').required('Email is required'),
    phoneNumber: string()
      .required('Phone number is required')
      .matches(phoneRegex, 'Phone number is not valid'),
  }),
  contactPrintivityCloudSalesRequest: object().shape({
    companyName: string().nullable(),
    industry: string().nullable(),
    companySize: string().nullable(),
    annualPrintSpend: string().nullable(),
    notes: string().nullable(),
    isCurrentCustomer: boolean(),
  }),
});

const ContactSalesForm = forwardRef((props, ref: ForwardedRef<unknown>) => {
  const [recaptchaResponse, setRecaptchaResponse] = React.useState<string>(null);

  const history = useHistory();

  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    submitForm({
      ...values,
      recaptchaResponse,
      formType: 'contactPrintivityCloudSalesRequest',
    }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        history.push('/contact-us/success');
      }
    });
  };
  return (
    <Formik
      initialValues={emptyContactSalesRequestForm}
      onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
        onSubmit(values, setStatus, setSubmitting, setErrors);
      }}
      validationSchema={contactSalesRequestSchema}
    >
      {() => (
        <Form className="w-full" data-cy="contactSalesForm">
          <Field
            name="userInput.name"
            inPlaceError
            setRef={ref}
            id="full-name"
            data-cy="userInput.name"
            component={Input}
            label="Your Name*"
            inputClassName="mb-1"
            className="scroll-mt-48"
          />
          <Field
            name="contactPrintivityCloudSalesRequest.companyName"
            inPlaceError
            component={Input}
            label="Company Name"
            inputClassName="mb-1"
            data-cy="contactPrintivityCloudSalesRequest.companyName"
          />
          <Field
            name="userInput.email"
            type="email"
            inPlaceError
            component={Input}
            label="Your Email*"
            inputClassName="mb-1"
            data-cy="userInput.email"
          />
          <Field
            name="userInput.phoneNumber"
            inPlaceError
            component={Input}
            label="Phone Number*"
            inputClassName="mb-1"
            data-cy="userInput.phoneNumber"
          />
          <Field
            name="contactPrintivityCloudSalesRequest.industry"
            inPlaceError
            component={Input}
            label="Industry"
            inputClassName="mb-1"
            data-cy="contactPrintivityCloudSalesRequest.industry"
          />
          <Field
            name="contactPrintivityCloudSalesRequest.companySize"
            component={Combobox}
            inPlaceError
            options={companySizes}
            creatable
            label="Company Size"
            data-cy="contactPrintivityCloudSalesRequest.companySize"
          />
          <Field
            name="contactPrintivityCloudSalesRequest.annualPrintSpend"
            inPlaceError
            component={Combobox}
            label="Annual Print Spend"
            inputClassName="mb-1"
            data-cy="contactPrintivityCloudSalesRequest.annualPrintSpend"
            options={annualPrintSpendOptions}
          />
          <Field
            name="contactPrintivityCloudSalesRequest.notes"
            rows={5}
            component={Textarea}
            label="Have something else to share?"
            data-cy="contactPrintivityCloudSalesRequest.notes"
          />
          <div className="mt-4 flex items-center justify-start space-x-2">
            <Field
              size="sm"
              name="contactPrintivityCloudSalesRequest.isCurrentCustomer"
              component={Checkbox}
              data-cy="contactPrintivityCloudSalesRequest.isCurrentCustomer"
            />
            <Label className="mt-2" placement="left">
              Current Printivity Customer?
            </Label>
          </div>
          <div className="sm:translate-0 -translate-x-8 scale-75 transform sm:scale-100 sm:transform-none">
            <Captcha verifyCallback={response => setRecaptchaResponse(response)} />
          </div>
          <div className="text-center mt-8">
            <Button color="blue" type="submit" size="md" dataCy="submitButton">
              Get Started
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default ContactSalesForm;

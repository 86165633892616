import * as React from 'react';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import LogoCentered from './LogoCentered';

interface Props {
  logoCentered?: boolean;
}

const Nav = ({ logoCentered }: Props) => (
  <div>
    {logoCentered ? (
      <LogoCentered />
    ) : (
      <>
        <IfDesktop>
          <DesktopNav />
        </IfDesktop>
        <IfTabletOrMobile>
          <MobileNav />
        </IfTabletOrMobile>
      </>
    )}
  </div>
);

export default Nav;

import * as React from 'react';
import Link from 'styleguide/components/Links/Link';
import { privacyPath } from 'bundles/App/routes';

const Header = () => (
  <>
    <div className="text-neutral-700 font-medium font-hvMedium text-sm mt-12 mb-8">REACH OUT</div>
    <h3 className="md:text-5xl text-4xl text-blue font-medium font-hvMedium">
      Get<span className="text-default"> in Touch</span>
    </h3>
    <p>
      Use the form below to get in touch. Our office hours are Monday through Friday, 9 am to 5 pm Eastern,
      and we attempt to respond to support requests within 1 business day.
    </p>

    <div className="bg-neutral-100 rounded-xl my-8 text-center p-6 text-sm text-gray-500 font-hvMedium">
      Don&apos;t worry. We do not sell your information. To learn more about our privacy practices, please
      visit
      <br />
      our{' '}
      <Link
        unstyled
        to={privacyPath}
        color="black"
        underline="always"
        className="text-sm text-gray-500 font-hvMedium"
      >
        Privacy Policy
      </Link>
      .
    </div>
  </>
);

export default Header;

import * as React from 'react';
import { slide as Menu } from 'react-burger-menu';
import logo from 'assets/images/PrintivityCloud/cloud-logo.png';
import { IconBurger, IconClose } from 'styleguide/icons';
import MobileMenuItem from 'styleguide/components/Nav/MobileNav/components/MobileMenuItem/MobileMenuItem';
import Image from 'styleguide/components/Image/Image';
import { contactEmail } from 'app/constants';
import Button from 'styleguide/components/Button/Button';
import Copyright from 'styleguide/components/Footer/BottomPart/Copyright';
import { servicePath } from 'bundles/App/routes';

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <div className="h-16 px-4 flex justify-between items-center fixed z-100 w-full bg-white">
        <div className="flex items-center">
          <Image url={logo} title="Printivity" alt="Printivity" className="w-20" />
        </div>
        <IconBurger onClick={toggleMenu} className="cursor-pointer" data-cy="mobile-menu-burger-btn" />
      </div>
      <div>
        <Menu
          isOpen={menuOpen}
          className="top-0 w-[320px] h-16 bg-white -ml-3"
          onStateChange={state => setMenuOpen(state.isOpen)}
          styles={{
            bmOverlay: { top: '0', left: '0', height: '100vh' },
            bmBurgerButton: { display: 'none' },
            bmMenuWrap: { height: '100vh', overflow: 'scroll' },
            bmItemList: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
          }}
        >
          <div>
            <MobileMenuItem
              Img={<Image url={logo} title="Printivity" alt="Printivity" className="w-20" />}
              Icon={<IconClose color="dark" className="!h-4 !w-4 cursor-pointer" onClick={closeMenu} />}
              linkTo="/"
              theme="white"
            />
            <div className="mt-20 flex flex-col items-center">
              <MobileMenuItem
                title="Introducing"
                withPadding
                thin
                theme="white"
                linkTo="/"
                className="mb-8"
              />
              <MobileMenuItem title="Benefits" withPadding thin theme="white" linkTo="/" className="mb-8" />
              <MobileMenuItem title="Who We Are" withPadding thin theme="white" linkTo="/" className="mb-8" />
            </div>
          </div>
          <div className="mb-8 !flex flex-col items-center">
            <a
              href={`mailto:${contactEmail}`}
              className="mt-6 no-underline paragraph-mobile cursor-pointer text-default text-sm"
            >
              {contactEmail}
            </a>
            <Button.AsNativeLink
              size="sm"
              buttonType="link"
              color="dark"
              outline
              className="!bg-transparent"
              target={servicePath.cloudEnterpriseServicesContactSales}
            >
              Get Started
            </Button.AsNativeLink>
            <div className="flex justify-center text-center px-12">
              <Copyright className="pt-6" />
            </div>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default MobileNav;

import * as React from 'react';
import logo from 'assets/images/PrintivityCloud/cloud-logo.png';
import { HashLink } from 'react-router-hash-link';
import printivityLogo from 'assets/images/printivity-logo.png';
import { Button, Link } from 'styleguide/components';
import { servicePath } from 'bundles/App/routes';
import Image from 'styleguide/components/Image/Image';

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const DesktopNav = () => (
  <nav className="px-2 bg-white border-b border-neutral-200 shadow flex items-center h-24 fixed z-100 w-full left-0">
    <div className="max-w-base flex items-center w-full justify-between mx-auto">
      <div className="flex gap-6 items-center">
        <Image
          url={logo}
          className="block mr-6 cursor-pointer w-[140px] h-auto"
          alt="Printivity Cloud"
          onClick={() => scrollWithOffset(document.getElementById('hero'))}
        />
        <Button size="sm" type="button" color="text">
          <HashLink smooth to="#introducing" className="no-underline text-neutral-700">
            Introducing
          </HashLink>
        </Button>
        <Button size="sm" type="button" color="text">
          <HashLink smooth to="#benefits" className="no-underline text-neutral-700" scroll={scrollWithOffset}>
            Benefits
          </HashLink>
        </Button>
        <Button size="sm" type="button" color="text">
          <HashLink smooth to="#whoWeAre" className="no-underline text-neutral-700" scroll={scrollWithOffset}>
            Who We Are
          </HashLink>
        </Button>
      </div>

      <div className="flex items-center">
        <Link color="black" underline="always" to="/">
          <Image url={printivityLogo} className="block h-14 w-auto mr-6" alt="Printivity" />
        </Link>
        <Button.AsNativeLink
          size="sm"
          buttonType="link"
          color="orange"
          outline
          target={servicePath.cloudEnterpriseServicesContactSales}
        >
          Get Started
        </Button.AsNativeLink>
      </div>
    </div>
  </nav>
);

export default DesktopNav;

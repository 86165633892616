import * as React from 'react';
import BaseCopyright from 'styleguide/components/Footer/BottomPart/Copyright';

const Copyright = () => (
  <section className="flex justify-center">
    <div className="py-8 flex w-full justify-around border-t border-gray-50 max-w-full 2xl:max-w-base">
      <BaseCopyright isEnterprise />
    </div>
  </section>
);

export default Copyright;

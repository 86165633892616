export const companySizes = [
  { key: '1 - 9', label: '1 - 9' },
  { key: '10 - 49', label: '10 - 49' },
  { key: '50 - 249', label: '50 - 249' },
  { key: '250 - 999', label: '250 - 999' },
  { key: '1000+', label: '1000+' },
];

export const annualPrintSpendOptions = [
  {
    key: 'Under $10,000',
    label: 'Under $10,000',
  },
  {
    key: '$25,000 - $99,999',
    label: '$25,000 - $99,999',
  },
  {
    key: '$100,000 - $999,999',
    label: '$100,000 - $999,999',
  },
  {
    key: '$1 Million - $5 Million',
    label: '$1 Million - $5 Million',
  },
];

import * as React from 'react';
import logo from 'assets/images/PrintivityCloud/cloud-logo.png';
import { Link } from 'styleguide/components';
import { servicePath } from 'bundles/App/routes';

const LogoCentered = () => (
  <nav className="bg-white border-b border-neutral-200 shadow flex justify-center items-center h-20">
    <Link color="black" underline="always" to={servicePath.cloudEnterpriseServices}>
      <img className="block w-auto md:w-[140px] h-[40px]" src={logo} alt="Printivity Cloud" />
    </Link>
  </nav>
);

export default LogoCentered;
